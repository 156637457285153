<template>
  <div>
    <b-row class="mb-3">
      <b-col>
        <b-card-text
          >Seleccione la fecha para su cita y en la tabla inferior seleccione su
          cita:</b-card-text
        >
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" sm="12" md="5" lg="4" xl="3">        
        <b-row>
          <b-col>
            <b-calendar
              v-model="fechaCita"
              @context="onSelectDate"
              locale="es-CO"
              selected-variant="primary"
              today-variant="danger"
              nav-button-variant="primary"
              class="mb-3"
            ></b-calendar>
          </b-col>
        </b-row>
        <b-row class="mb-3">
          <b-col>
            <b-button @click="prevStep();setCupCita(null);" variant="dark" class="btn-steps"
              >Anterior</b-button
            >
            <b-button @click="cancelar()" variant="dark" class="btn-steps"
              >Cancelar</b-button
            >
          </b-col>
        </b-row>
      </b-col>

      <b-col>
        <b-row class="mb-2" v-show="listSedes.length > 0">
          <b-col>
            <label for="">
              <b>Para filtrar la disponibilidad en una sede, por favor seleccione una a continuación:</b>
            </label>
            <Multiselect  v-model="sedeSelected" :options="listSedes" track-by="value" :custom-label="(item) => item.text" placeholder="Escribe para buscar..." @input="filtrarSede()">
              <template slot="option" slot-scope="props">                
                <div class="option__desc">
                  <span class="option__title">{{ props.option.text }}</span>
                  <br>
                  <span class="option__small" v-show="props.option.dir"><b>{{ props.option.dir }}</b></span>
                </div>
              </template>
            </Multiselect>
          </b-col>
        </b-row>
        <b-row class="mb-2" v-show="listMedicos.length > 0 && !esAfiliadoSaludTotal && cita.nomEspecialidad != 'MAMOGRAFIAS'">
          <b-col>
            <label for="">
              <b>Filtrar por especialista:</b>
            </label>
            <Multiselect  v-model="medicoSelected" :options="listMedicos" track-by="value" :custom-label="(item) => item.text" placeholder="Escribe para buscar..." @input="filtrarMedico()">
              <!-- <template slot="option" slot-scope="props">                
                <div class="option__desc">
                  <span class="option__title">{{ props.option.text }}</span>
                  <br>
                  <span class="option__small" v-show="props.option.dir"><b>{{ props.option.dir }}</b></span>
                </div>
              </template> -->
            </Multiselect>
          </b-col>
        </b-row>
        <b-table
          :fields="fields"
          :items="items"
          :per-page="perPage"
          :currentPage="currentPage"
          small
          hover
          head-variant="dark"
          bordered
          :tbody-tr-class="rowClass"
          @row-clicked="seleccionar($event)"
          id="tbl-agenda"
          v-show="!loading && !asignando"
        >
          <template #cell(sede)="data">
            <b-row>
              <b-col>
                {{ data.item.sede }}
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <span class="text-muted" style="font-size: small">{{
                  data.item.dirSede
                }}</span>
              </b-col>
            </b-row>
          </template>
        </b-table>
        <b-row align-h="center" v-show="loading">
          <b-container>
            <b-row align-h="center">
              <div class="d-flex justify-content-center">
                <b-icon
                  icon="arrow-repeat"
                  animation="spin-reverse"
                  font-scale="4"
                ></b-icon>
              </div>
            </b-row>
            <b-row align-h="center">
              <div class="d-flex justify-content-center">
                <b-card-text>Consultando agenda disponible...</b-card-text>
              </div>
            </b-row>
          </b-container>
        </b-row>
        <b-row align-h="center" v-show="asignando">
          <b-container>
            <b-row align-h="center">
              <div class="d-flex justify-content-center">
                <b-icon
                  icon="arrow-repeat"
                  animation="spin-reverse"
                  font-scale="4"
                ></b-icon>
              </div>
            </b-row>
            <b-row align-h="center">
              <div class="d-flex justify-content-center">
                <b-card-text>Asignando cita, un momento...</b-card-text>
              </div>
            </b-row>
          </b-container>
        </b-row>
        <b-row v-if="items.length > 0 && !loading && !asignando">
          <b-col>
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="tbl-agenda"
              align="center"
            ></b-pagination>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>
<script>
  import { mapActions, mapState } from "vuex";
  import axios from 'axios'
  var moment = require('moment-timezone');
  import Multiselect from 'vue-multiselect'
  import textosMensajes from '../../helpers/textosMensajes'
  export default {
    components: {
      Multiselect
    },
    data: () => {
      return {
        loading: false,
        asignando: false,
        context: null,
        fields: [
          { key: "medico", label: "Médico" },
          { key: "fecha", label: "Fecha" },
          { key: "hora", label: "Hora" },
          { key: "sede", label: "Sede" },
        ],
        items: [],
        allItems: [],
        fechaCita: null,
        currentPage: 1,
        perPage: 10,
        showError: false,
        error: null,
        listSedes: [],
        sedeSelected: null,
        nombreSedes: [],
        listMedicos: [],
        medicoSelected: null
      };
    },
    computed: {
      ...mapState(["userLogged", "cita", "esAfiliadoSaludTotal"]),
      rows() {
        return this.items.length;
      },
    },
    async mounted() {
      // this.getInfoAfiliado().then((rta) => {
      //   console.log("info afiliado", rta);
      // });
      if(this.$store.state.cita.nomEspecialidad == 'MAMOGRAFIA' && this.$store.state.cita.origen == 'DX'){
        //Pregunta si es menor a 40 años
        if(this.$store.state.cita.afiliado.edad < 40){
          //Se envia el paciente al Call Center
          await this.showModalEdad();
          setTimeout(() => {
            this.resetStep();
            this.$router.replace("/");
            // this.logout().then((rta) => {
            //   this.$router.replace("/");
            // });
          }, 700);
        }else{
          //Preguntamos si le han realizado mastectomia
          if(this.$store.state.cita.cups.codigo == '876801'){
            this.showPreguntaMastectomia()            
          } else{
            this.showPreguntaEscaleras()
          }        
          
        }
      }

      //
      

      this.loadSedes();
      // this.loadMedicos();
      
    },
    methods: {
      ...mapActions([
        "setFechaCita",
        "getAgenda",
        "setIdCita",
        "getInfoAfiliado",
        "logout",
        "asignarCita",
        "prevStep",
        "resetStep",
        "registrarEstadistica",
        "getSedesAfiliado",
        "setCupCita"
      ]),
      async onSelectDate(ctx) {
        // this.showError = false;
        // this.error = "";
        if (
          ctx.selectedDate &&
          this.cita.fecha !== moment(ctx.selectedDate).format("MM-DD-YYYY")
        ) {
          this.setFechaCita(moment(this.fechaCita).format("MM-DD-YYYY"));
          this.loading = true;

          // console.log('aqui 2')
          // let condiciones = await axios.get(`${this.$store.state.urlProxyApi}/agenda-dx/get-validaciones-cup`,{params: {cup: this.$store.state.cita.cups.codigo}})
          // console.log('aqui',condiciones)
          this.getAgenda()
            .then((rta) => {       
              this.items = [];
              if (rta.length == 0) {
                this.$toastr.e(
                  "La fecha seleccionada no es válida o no tiene disponibilidad."
                );
                // this.showError = true;
                // this.error =
                //   "La fecha seleccionada no es válida o no tiene disponibilidad.";
              } else {
                this.allItems = rta ;// this.$store.state.cita.origen == 'ST' ? rta : rta.filter(e => this.nombreSedes.find(x => x.sede == e.sede)) 
                this.items = this.allItems;
                this.listSedes = [];
                this.sedeSelected = {
                  value: null,
                  text: "Todas las Sedes",
                  dir: null
                };
                this.listMedicos = [];
                this.medicoSelected = {
                  value: null,
                  text: "Todos los Especialistas"
                };
                this.items.forEach(item => {                                  
                  if(!this.listSedes.find(e => e.value == item.codSede)){
                    this.listSedes.push({
                      value: item.codSede,
                      text: item.sede,
                      dir: item.dirSede
                    });
                  }
                  if(!this.listMedicos.find(e => e.value == item.medico)){
                    this.listMedicos.push({
                      value: item.medico,
                      text: item.medico
                    });
                  }
                });
                this.listSedes.unshift({
                  value: null,
                  text: "Todas las Sedes",
                  dir: null
                })
                this.listMedicos = this.listMedicos.sort((a,b)=> {
                  if(a.value > b.value){
                    return 1;
                  }
                  if(a.value < b.value){
                    return -1;
                  }
                  return 0
                })
                this.listMedicos.unshift({
                  value: null,
                  text: "Todos los Especialistas"
                })
              }
              this.loading = false;
            })
            .catch((error) => {
              this.loading = false;
              if (error.status == 401) {
                this.resetStep();
                this.$router.replace("/");
                // this.logout().then((rta) => {
                //   this.$router.replace("/");
                // });
              }
            });
        }
      },
      filtrarSede(){
        this.items = [];
        if(this.sedeSelected && this.sedeSelected.value){
          this.items = this.allItems.filter(e => e.codSede == this.sedeSelected.value)
        }else{
          this.items = this.allItems;
        }
      },
      filtrarMedico(){
        this.items = [];
        if(this.medicoSelected && this.medicoSelected.value){
          this.items = this.allItems.filter(e => e.medico == this.medicoSelected.value)
        }else{
          this.items = this.allItems;
        }
      },
      rowClass(item, type) {
        if (!item || type !== "row") return;
        if (this.cita.idCita && item.idCita === this.cita.idCita) {
          return "row-selected cursor-pointer";
        } else {
          return "cursor-pointer";
        }
      },
      seleccionar(item) {        
        this.$bvModal
          .msgBoxConfirm(
            `Está seguro de asignar esta cita para el ${item.fecha} a las ${item.hora}?`,
            {
              title: "Confirmar cita",
              size: "md",
              buttonSize: "lg",
              okVariant: "primary",
              okTitle: "Si",
              cancelVariant: "danger",
              cancelTitle: "No",
              footerClass: "p-2",
              hideHeaderClose: true,
              centered: true,
            }
          )
          .then((value) => {
            let dataToSend = {
              ...item
            }
            if (value === true) {
              this.asignando = true;
              //Si el paciente tiene implantes mamarios, se toman dos espacios consecutivos de la agenda
              //Si el paciente es de ecografia y la cantidad de procedimientos es mas de uno, se toman dos espacios
              if(this.$store.state.cita.origen == 'DX'){
                if((this.$store.state.cita.afiliado.implantes)){                  
                    let segundaCita = this.getSiguienteCita(item);
                    if(!segundaCita){
                      this.showModalErrorDobleEspacio();
                      return;                  
                    }
                    let espaciosCitas = this.getArrayEspaciosCita(item,2);
                    if(espaciosCitas.length == 0){
                      this.showModalErrorDobleEspacio();
                      return; 
                    }
                    dataToSend = {
                      ...item,
                      espaciosCitas
                    }
                }else{
                  let espaciosCitas = this.getArrayEspaciosCita(item,this.$store.state.cita.cups.espacios);
                  if(espaciosCitas.length == 0){
                    this.showModalErrorDobleEspacio();
                    return; 
                  }
                  dataToSend = {
                    ...item,
                    espaciosCitas
                  }
                }    

              }
                     
              this.asignarCita(dataToSend)
                .then((rta) => {                  
                  if (rta.Error && rta.Error !== null) {
                    this.$bvModal.msgBoxOk(rta.Error, {
                      title: "Asignación de cita",
                      size: "md",
                      buttonSize: "lg",
                      okVariant: "primary",
                      okTitle: "Cerrar",
                      footerClass: "p-2",
                      hideHeaderClose: false,
                      centered: true,
                      okOnly: true,
                    });
                    this.asignando = false;
                    return;
                  }
                  this.asignando = false;
                  if(this.$store.state.cita.origen == 'ST'){
                    this.showConfirmacionST(rta);
                  }else{
                    this.showConfirmacionDX(rta,this.$store.state.cita.especialidad);
                  }
                })
                .catch((error) => {
                  // this.error = error;
                  // this.showError = true;
                  console.log(error);
                  // this.$toastr.e(error);
                  this.$bvModal.msgBoxConfirm(error, {
                    title: "Asignación de cita",
                    size: "md",
                    buttonSize: "lg",
                    okVariant: "primary",
                    okTitle: "Cerrar",
                    footerClass: "p-2",
                    hideHeaderClose: false,
                    centered: true,
                    okOnly: true,
                  });
                  this.asignando = false;
                });
            }
          })
          .catch((error) => {
            this.$toastr.e(error);
            this.resetStep();
            this.$router.replace("/");
            // if (error.status == 401) {
            //   this.logout().then((rta) => {
            //     this.$router.replace("/login");
            //   });
            // }
          });
      },
      async cancelar() {
        await this.resetStep();
        this.$router.replace("/");
      },
      showModalErrorDobleEspacio(){
        let message = `Estimada protegida, informamos que la cita no puede ser asignada para la hora seleccionada. Por favor seleccione otro horario.`;
        const h = this.$createElement;
        const textModal = h("div", {
          domProps: {
            innerHTML: message
          },
        });
        return this.$bvModal
          .msgBoxOk(textModal, {
            title: "Agendamiento Virtual - Cita Mamografía",
            size: "md",            
            okVariant: "primary",
            okTitle: "Aceptar",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
            okOnly: true
          })
      },
      showModalEdad(){
        let message = `Estimada protegida, informamos que el estudio de mamografía se realiza en mujeres mayores de 40 años, es necesario validar el motivo por el cual le envían el examen, por lo cual lo invitamos a comunicarse con nuestra central de citas 6076474788.`;
        const h = this.$createElement;
        const textModal = h("div", {
          domProps: {
            innerHTML: message
          },
        });
        this.$bvModal
          .msgBoxOk(textModal, {
            title: "Agendamiento Virtual - Cita Mamografía",
            size: "md",            
            okVariant: "primary",
            okTitle: "Aceptar",
            footerClass: "p-2",
            hideHeaderClose: true,
            centered: true,
            okOnly: true,
            noCloseOnBackdrop: true,
            noCloseOnEsc: true
          }).then(value => {
            console.log(value)
          })
      },
      showModalImplantes(){
        this.$store.state.cita.afiliado.implantes = false;
        let message = `La paciente cuenta con prótesis mamaria?:`;
        const h = this.$createElement;
        const textModal = h("div", {
          domProps: {
            innerHTML: message
          },
        });
        this.$bvModal
          .msgBoxConfirm(textModal, {
            title: "Agendamiento Virtual - Cita Mamografía",
            size: "md",            
            okVariant: "primary",
            okTitle: "Si",
            cancelVariant: "danger",
            cancelTitle: "No",
            footerClass: "p-2",
            hideHeaderClose: true,
            centered: true,
            noCloseOnBackdrop: true,
            noCloseOnEsc: true
          }).then(rta => {
            this.$store.state.cita.afiliado.implantes = rta;
          })
      },
      showModalRedirigirCallCenter(msg){
        let message = msg;
        const h = this.$createElement;
        const textModal = h("div", {
          domProps: {
            innerHTML: message
          },
        });
        return this.$bvModal
          .msgBoxOk(textModal, {
            title: "Información",
            size: "md",            
            okVariant: "primary",
            okTitle: "Aceptar",
            footerClass: "p-2",
            hideHeaderClose: true,
            centered: true,
            okOnly: true,
            noCloseOnBackdrop: true,
            noCloseOnEsc: true
          });          
      },
      showConfirmacionST(rta){
        let msgPresencial = `Señor(a) ${rta.nombreProtegido}, la cita de ${
          rta.especialidad
        } ha sido asignada con el Dr(a) ${
          rta.medico
        }, para el ${moment(rta.fecha)
          .locale("es")
          .format("dddd LL")} a las ${
          rta.hora
        }. Por favor tome nota de esta información y diríjase a la unidad de atención asignada ${
          rta.Lugar
        }, ubicada en ${
          rta.direccion
        }, con su carné y su documento de identidad, con 15 minutos de anticipación para trámites administrativos.<br><br>
          Si por algún motivo debes cancelar esta cita puedes hacerlo en esta misma utilidad, en el menú principal, en la sección <b>Mis Citas</b>.<br><br>Valor Cuota Moderadora: $${
            rta.ValorPago
          }  Número Cita: ${rta.idCita}`;
        let msgTeleorientacion = `Señor(a) ${rta.nombreProtegido}, la cita de ${
          rta.especialidad
        } ha sido asignada con el Dr(a) ${
          rta.medico
        }, para el ${moment(rta.fecha)
          .locale("es")
          .format("dddd LL")} a las ${
          rta.hora
        }. <br><br><b>Recuerde que no debe asistir a la sede, tome nota de esta información y esté atento a la llamada del personal médico.</b><br><br>
          Si por algún motivo debes cancelar esta cita puedes hacerlo en esta misma utilidad, en el menú principal, en la sección <b>Mis Citas</b>.<br><br>Valor Cuota Moderadora: $${
            rta.ValorPago
          }  Número Cita: ${rta.idCita}`;
        const h = this.$createElement;
        const textModal = h("div", {
          domProps: {
            innerHTML: rta.especialidad == 'MEDICINA GENERAL - TELEORIENTACION' ? msgTeleorientacion : msgPresencial
          },
        });
        this.$bvModal
          .msgBoxOk(textModal, {
            title: "Confirmación de Cita - "+rta.especialidad,
            size: "md",
            centered: true,
          })
          .then((value) => {
            this.resetStep();
            this.$router.replace("/");
            // this.logout().then((rta) => {
            //   this.$router.replace("/login");
            // });
          });
      },
      showConfirmacionDX(rta,especialidad){
        let msg = `Señor(a) <b>${rta.NomPaciente}</b>, Por favor tome nota de la siguiente información: <br><br>
        La cita de <b>${
          rta.Especialidad
        }</b> ha sido asignada para el <b>${moment(rta.Fecha.substr(0,10)).locale("es").format("dddd LL")}</b> a las <b>${moment(moment(rta.Hora, 'HH:mm A')).format('hh:mm A')}</b>.  en la sede de <b>Alianza Diagnóstica</b> en la ciudad de <b>${rta.NomCiudad}</b>, ubicada en la dirección <b>${rta.DirSede}</b>.<br><br>
          Si por algún motivo debe cancelar esta cita puede hacerlo en esta misma utilidad, en el menú principal, en la sección <b>Mis Citas</b>.<br><br>
        `;  
        if(especialidad=='MAMO')      {
          msg = msg + textosMensajes.textoGeneralMamografia
        }else{
          msg = msg + this.$store.state.cita.cups.textoPreparacion
        }
        const h = this.$createElement;
        const textModal = h("div", {
          domProps: {
            innerHTML: msg
          },
        });
        this.$bvModal
          .msgBoxOk(textModal, {
            title: "Confirmación de Cita - "+rta.Especialidad,
            size: "md",
            centered: true,
            okTitle: "Aceptar",
          })
          .then((value) => {
            this.resetStep();
            this.$router.replace("/");
            // this.logout().then((rta) => {
            //   this.$router.replace("/login");
            // });
          });
      },
      showPreguntaMastectomia(){
        let msg = `
          ¿La paciente le han realizado <b>mastectomía</b>?
        `;        
        const h = this.$createElement;
        const textModal = h("div", {
          domProps: {
            innerHTML: msg
          },
        });
        return this.$bvModal
          .msgBoxConfirm(textModal, {
            title: "Agendamiento Virtual - Cita Mamografía",
            size: "md",            
            okVariant: "primary",
            okTitle: "Si",
            cancelVariant: "danger",
            cancelTitle: "No",
            footerClass: "p-2",
            hideHeaderClose: true,
            centered: true,
            noCloseOnBackdrop: true,
            noCloseOnEsc: true
          }).then(async rta => {
            if(!rta){
              this.showModalRedirigirCallCenter(`Estimada protegida la invitamos a comunicarse con nuestra central de citas 6076474788, para agendar su cita dado que la autorización generada no corresponde al servicio requerido.`).then(rta => {                
                this.logout().then((rta) => {
                  this.$router.replace("/login");
                });
              })       
            }else{
              this.showPreguntaEscaleras();            
            }
          })
      },
      showPreguntaEscaleras(){
        let msg = `
           ¿Puede subir escaleras a un 2º piso sin ninguna dificultad?
        `;        
        const h = this.$createElement;
        const textModal = h("div", {
          domProps: {
            innerHTML: msg
          },
        });
        return this.$bvModal
          .msgBoxConfirm(textModal, {
            title: "Agendamiento Virtual - Cita Mamografía",
            size: "md",            
            okVariant: "primary",
            okTitle: "Si",
            cancelVariant: "danger",
            cancelTitle: "No",
            footerClass: "p-2",
            hideHeaderClose: true,
            centered: true,
            noCloseOnBackdrop: true,
            noCloseOnEsc: true
          }).then(async rta => {
            if(!rta){
              this.showModalRedirigirCallCenter(`Estimada protegida la invitamos a comunicarse con nuestra central de citas 6076474788, para agendar su cita en sede donde no requiera el subir escaleras.`).then(rta => {                
                this.resetStep();
                this.$router.replace("/");
                // this.logout().then((rta) => {
                //   this.$router.replace("/login");
                // });
              })       
            }else{
              this.showModalImplantes();            
            }
          })
      },
      showPreguntaCups(tipo,texto){
        let msg = texto;        
        const h = this.$createElement;
        const textModal = h("div", {
          domProps: {
            innerHTML: msg
          },
        });
        this.$bvModal
          .msgBoxConfirm(textModal, {
            title: "Agendamiento Virtual",
            size: "md",            
            okVariant: "primary",
            okTitle: "Si",
            cancelVariant: "danger",
            cancelTitle: "No",
            footerClass: "p-2",
            hideHeaderClose: true,
            centered: true,
            noCloseOnBackdrop: true,
            noCloseOnEsc: true
          }).then(async rta => {
           console.log('rta modal',rta)
          })
      },
      getSiguienteCita(item){
        (moment(moment(item.hora,'hh:mm a')).add(item.duracion,'minutes').format('hh:mm a'))
        let found = this.items.find(e => {
          return e.fecha == item.fecha && e.sede == item.sede && e.hora == moment(moment(item.hora,'hh:mm a')).add(item.duracion,'minutes').format('hh:mm a').toUpperCase()
        })
        return found ? found : null
      },
      getArrayEspaciosCita(item,cantidad){
        let arraySalida = []
        let horaIni = item.hora;
        arraySalida.push(item)
        for (let index = 1; index < cantidad; index++) {
          horaIni = (moment(moment(horaIni,'hh:mm a')).add(item.duracion,'minutes').format('hh:mm a'))
          let found = this.items.find(e => {
            return e.fecha == item.fecha && e.sede == item.sede && e.hora == horaIni.toUpperCase()
          })  
          if(!found){
            return null
          }
          arraySalida.push(found)
        }
        return arraySalida
      },
      loadSedes(){
        this.getSedesAfiliado({
          codCiudad: this.$store.state.grupoFamiliar[0].CiudadId,
          especialidad: this.$store.state.cita.especialidad
        }).then(rta => {
          this.nombreSedes = rta
        })
      }
    },
  };
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
